import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 544.000000 514.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,514.000000) scale(0.100000,-0.100000)" >


<path d="M2714 3521 c-45 -27 -76 -76 -82 -127 l-5 -44 337 0 336 0 0 33 c0
61 -45 124 -103 146 -17 7 -114 11 -240 11 -191 0 -215 -2 -243 -19z m339 -80
c4 -1 7 -10 7 -21 0 -19 -7 -20 -95 -20 -85 0 -95 2 -95 18 0 10 2 21 5 23 4
4 136 4 178 0z m107 -21 c0 -21 -33 -29 -45 -10 -9 15 4 30 26 30 12 0 19 -7
19 -20z"/>
<path d="M2433 3387 c-64 -40 -142 -108 -143 -124 0 -9 21 -13 70 -13 68 0 71
1 81 27 19 50 43 98 56 111 16 17 17 32 1 31 -7 0 -36 -15 -65 -32z"/>
<path d="M2547 3378 c-33 -45 -68 -114 -60 -122 4 -3 26 -6 50 -6 l43 0 0 80
c0 44 -2 80 -4 80 -3 0 -16 -15 -29 -32z"/>
<path d="M2632 2988 l3 -313 330 0 330 0 3 313 2 312 -335 0 -335 0 2 -312z"/>
<path d="M2254 3202 c-15 -10 -42 -89 -50 -144 l-6 -48 94 0 95 0 6 53 c3 28
9 73 13 100 l7 47 -74 0 c-41 0 -79 -4 -85 -8z"/>
<path d="M2465 3198 c-10 -33 -25 -123 -25 -153 l0 -35 70 0 70 0 0 100 0 100
-55 0 c-36 0 -57 -4 -60 -12z"/>
<path d="M2200 2941 c0 -32 23 -119 39 -149 11 -20 19 -22 92 -22 l80 0 -6 58
c-4 31 -10 74 -14 95 l-6 39 -92 2 -93 2 0 -25z"/>
<path d="M2442 2951 c-5 -14 14 -156 24 -172 3 -5 30 -9 60 -9 l55 0 -3 96 -3
96 -64 1 c-46 2 -65 -2 -69 -12z"/>
<path d="M2285 2721 c-2 -5 19 -31 49 -59 60 -57 176 -121 176 -98 0 8 -4 18
-10 21 -12 7 -60 107 -60 123 0 7 -3 12 -7 12 -101 7 -144 7 -148 1z"/>
<path d="M2485 2722 c-7 -7 54 -118 76 -138 18 -17 19 -15 19 59 0 73 -1 77
-22 78 -13 0 -33 2 -46 3 -12 2 -24 0 -27 -2z"/>
<path d="M2630 2575 c0 -72 19 -116 66 -151 l37 -29 230 -3 c127 -2 233 1 236
5 3 5 19 16 34 26 46 30 67 74 67 145 l0 62 -335 0 -335 0 0 -55z m368 -7 c20
-20 15 -66 -9 -73 -38 -12 -69 2 -69 33 0 33 16 52 45 52 12 0 26 -5 33 -12z"/>
<path d="M1948 2013 c-15 -9 -29 -26 -32 -37 -10 -37 -6 -420 4 -458 12 -42
34 -58 80 -58 69 0 90 39 90 167 0 73 13 103 45 103 25 0 35 -37 35 -134 0
-116 12 -136 85 -136 81 0 102 50 93 219 -8 132 -36 171 -125 171 -29 0 -70
-7 -90 -15 -19 -8 -37 -15 -39 -15 -2 0 -4 36 -4 79 0 60 -4 84 -18 101 -24
29 -88 36 -124 13z"/>
<path d="M310 1999 c-54 -24 -99 -71 -130 -134 -41 -85 -37 -338 6 -386 12
-14 31 -19 65 -19 61 0 89 26 89 82 l0 38 70 0 70 0 0 -31 c0 -32 15 -69 34
-81 20 -14 104 -9 120 7 25 25 39 122 33 235 -6 127 -19 168 -69 225 -73 83
-189 109 -288 64z m136 -117 c23 -15 34 -53 34 -116 l0 -66 -70 0 -70 0 0 53
c0 68 16 125 38 130 28 8 56 7 68 -1z"/>
<path d="M772 2000 c-31 -29 -35 -71 -30 -291 7 -238 10 -249 88 -249 41 0 52
4 69 26 20 25 21 39 21 264 0 217 -2 238 -18 253 -27 24 -102 22 -130 -3z"/>
<path d="M3450 1993 c-20 -26 -21 -35 -18 -265 3 -223 4 -238 23 -253 16 -13
46 -15 165 -13 142 3 146 4 193 33 38 24 55 44 80 94 28 58 31 73 31 150 -1
101 -25 162 -82 212 -66 58 -103 69 -244 69 -126 0 -127 0 -148 -27z m220
-100 c37 -21 51 -54 57 -128 9 -103 -32 -195 -87 -195 -19 0 -20 5 -20 170 0
117 3 170 11 170 6 0 24 -7 39 -17z"/>
<path d="M4625 1974 c-54 -19 -75 -47 -75 -98 0 -35 -4 -43 -30 -58 -42 -24
-39 -71 5 -79 18 -4 21 -14 26 -99 3 -52 11 -105 18 -118 31 -57 140 -82 201
-45 38 22 72 85 68 125 -4 40 -33 50 -54 19 -9 -12 -24 -21 -35 -21 -17 0 -19
7 -19 65 l0 65 31 0 c46 0 69 16 69 50 0 34 -23 50 -69 50 l-31 0 0 61 c0 99
-19 114 -105 83z"/>
<path d="M2560 1851 c-82 -19 -123 -52 -133 -109 -13 -69 25 -106 146 -143 42
-12 67 -25 64 -32 -6 -19 -75 -20 -118 -3 -74 30 -114 13 -87 -37 19 -37 48
-56 106 -68 137 -28 282 32 282 118 0 71 -25 94 -160 147 -64 24 -81 56 -31
56 36 0 77 -15 97 -35 20 -20 63 -19 71 1 10 26 -14 62 -54 83 -45 22 -133 33
-183 22z"/>
<path d="M2975 1836 c-59 -36 -78 -76 -83 -171 -3 -69 0 -86 20 -124 13 -25
36 -54 51 -65 34 -25 102 -28 165 -7 32 11 45 12 54 3 20 -20 105 -15 128 8
18 18 20 33 20 170 0 147 -1 152 -25 175 -30 31 -86 33 -121 6 -23 -18 -24
-18 -70 5 -60 30 -89 30 -139 0z m170 -181 l0 -90 -27 -3 c-22 -3 -30 2 -38
23 -13 35 -13 94 1 134 9 27 16 32 38 29 26 -3 26 -3 26 -93z"/>
<path d="M4095 1851 c-36 -15 -85 -74 -96 -116 -6 -22 -9 -70 -7 -107 11 -145
99 -204 236 -159 32 11 45 12 54 3 25 -25 119 -10 140 22 4 6 8 77 8 157 0
173 -6 188 -81 196 -38 4 -50 2 -65 -16 l-20 -20 -49 24 c-50 26 -85 30 -120
16z m150 -196 c0 -89 0 -90 -26 -93 -19 -2 -28 3 -37 23 -15 33 -15 92 -1 134
9 27 16 32 38 29 26 -3 26 -3 26 -93z"/>
<path d="M5005 1844 c-68 -37 -95 -94 -95 -199 0 -68 23 -127 64 -165 22 -19
37 -24 80 -24 29 0 72 6 95 14 32 10 44 11 53 2 21 -21 105 -15 129 9 19 19
20 30 17 174 -3 176 -7 184 -79 192 -35 4 -49 0 -67 -15 -23 -20 -24 -20 -55
-2 -47 28 -106 33 -142 14z m163 -186 c2 -77 1 -88 -16 -97 -14 -7 -24 -6 -38
4 -16 11 -19 26 -19 90 0 80 9 98 50 93 18 -3 20 -12 23 -90z"/>
<path d="M1038 1836 c-30 -22 -38 -64 -38 -194 0 -163 6 -176 80 -180 88 -5
94 4 98 140 3 67 8 116 15 120 6 3 27 -1 46 -11 66 -32 104 -9 103 61 -1 71
-57 97 -137 64 -30 -13 -38 -13 -62 -1 -34 18 -82 19 -105 1z"/>
<path d="M1427 1838 c-33 -25 -47 -179 -26 -278 15 -71 46 -100 108 -100 27 0
72 8 101 17 50 16 53 16 65 -1 10 -13 25 -16 71 -14 79 4 84 14 84 193 0 159
-7 180 -65 191 -84 15 -108 -14 -115 -145 -4 -81 -8 -96 -26 -108 -19 -12 -24
-12 -38 2 -13 13 -16 38 -16 116 0 86 -3 102 -20 119 -23 23 -96 28 -123 8z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
